<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-10-21 10:04:30
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2022-04-28 10:21:52
 * @ Description: Fixer's industry management page in the admin dashboard section of the app.
 -->

<template>
    <v-row
        id="industryContainer"
        :style="$vuetify.breakpoint.mdAndUp ? 'max-height: calc(100vh - 108px);' : 'max-height: calc(100vh - 72px);'"
        style="overflow-y: auto;"
        justify="center"
    >
        <v-col
            cols="12"
            :style="$vuetify.breakpoint.lgAndUp ? 'max-width: 1440px;' : 'max-width: 960px;'"
        >
            <v-row
                style="position: relative;"
            >
                <!-- Add Industry -->
                <v-col
                    cols="12"
                    class="os-22-sb"
                >
                    Manage Industries
                </v-col>

                <v-col
                    cols="12"
                    class="pb-0"
                >
                    <div
                        class="os-17-sb px-3"
                        style="background-color: white; height: 60px; display: flex; align-items: center;"
                    >
                        All Industries
                        <v-spacer />
                        <v-btn
                            class="white--text no-uppercase rounded-lg os-13-sb"
                            color="#FFA858"
                            v-if="!showAddEditIndustryBoolean"
                            @click="showAddEditIndustry(true)"
                            style="position: absolute; right: 16px; top: 10px;"
                        >
                            <span v-if="!$store.state.general.customBreakpoints.xxs">Add Industry</span>
                            <v-icon
                                class="ml-1"
                            >
                                {{ mdiPlusCircleOutline }}
                            </v-icon>
                        </v-btn>
                    </div>
                </v-col>
                <!-- Add Industry -->

                <!-- Industries -->
                <v-col
                    cols="12"
                    class="pt-0"
                >
                    <v-data-table
                        :headers="industryHeaders"
                        :items="industries"
                        item-key="id"
                        hide-default-footer
                        :no-data-text="noDataText"
                        :no-results-text="noResultsText"
                        :search="search"
                        :items-per-page="-1"
                    >
                        <!-- Company Profile -->
                        <template v-slot:[`item.is_profile_company`]="{ item }">
                            <v-checkbox
                                v-model="item.is_profile_company"
                                :ripple="false"
                                @change="upsertIndustry(true, item)"
                            />
                        </template>
                        <!-- Company Profile -->

                        <!-- Individual Profile -->
                        <template v-slot:[`item.is_profile_individual`]="{ item }">
                            <v-checkbox
                                v-model="item.is_profile_individual"
                                :ripple="false"
                                @change="upsertIndustry(true, item)"
                            />
                        </template>
                        <!-- Individual Profile -->

                        <!-- Job -->
                        <template v-slot:[`item.is_job`]="{ item }">
                            <v-checkbox
                                v-model="item.is_job"
                                :ripple="false"
                                @change="upsertIndustry(true, item)"
                            />
                        </template>
                        <!-- Job -->

                        <!-- Professional Service -->
                        <template v-slot:[`item.is_prof_service`]="{ item }">
                            <v-checkbox
                                v-model="item.is_prof_service"
                                :ripple="false"
                                @change="upsertIndustry(true, item)"
                            />
                        </template>
                        <!-- Professional Service -->

                        <template v-slot:[`item.action`]="{ item }">
                            <v-btn
                                icon
                                @click="menuItemAction ('Edit', item)"
                            >
                                <v-icon
                                    color="#2E567A"
                                    style="opacity: 0.65;"
                                    size="20"
                                >
                                    {{ mdiArrowRightThinCircleOutline }}
                                </v-icon>
                            </v-btn>
                            <!-- <v-btn
                                icon
                            >
                                <v-icon
                                    color="#2E567A"
                                    style="opacity: 0.65;"
                                    size="20"
                                >
                                    {{ mdiPrinterOutline }}
                                </v-icon>
                            </v-btn> -->
                            <!-- <v-btn
                                icon
                                @click="menuItemAction ('Delete', item)"
                            >
                                <v-icon
                                    color="#2E567A"
                                    style="opacity: 0.65;"
                                    size="20"
                                >
                                    {{ mdiTrashCanOutline }}
                                </v-icon>
                            </v-btn> -->
                        </template>
                    </v-data-table>
                </v-col>
                <!-- Industries -->

                <v-col
                    v-if="isLoading"
                    class="os-12-r text-center"
                    style="color: rgba(0, 0, 0, 0.38);"
                    cols="12"
                >
                    Loading industries...
                    <br />
                    <br />
                    <v-progress-circular
                        indeterminate
                        color="#FFA858"
                    />
                </v-col>
            </v-row>

            <!-- Add View -->
            <!-- Desktop -->
            <v-row
                v-if="showAddEditIndustryBoolean && $vuetify.breakpoint.mdAndUp"
                style="position: absolute; z-index: 1; top: 0; background-color: #F9FBFD; width: 100%; min-height: 100%;"
                class="mt-n1"
            >
                <v-col
                    cols="9"
                >
                    <v-row>
                        <!-- Heading -->
                        <v-col
                            cols="12"
                            class="os-22-sb"
                        >
                            <span v-if="action === 'add'">Add</span><span v-else-if="action === 'edit'">Edit</span> an Industry
                        </v-col>
                        <v-col
                            cols="7"
                            class="py-0"
                        >
                            <v-divider />
                        </v-col>
                        <!-- Heading -->

                        <!-- Industry Form -->
                        <v-col
                            cols="7"
                        >
                            <v-form
                                v-model="validIndustryForm"
                                ref="industryForm"
                            >
                                <v-row>
                                    <v-col
                                        cols="12"
                                        class="pb-0 os-15-sb"
                                    >
                                        Industry<span style="color: red;">*</span>
                                        <br />
                                        <v-text-field
                                            dense
                                            outlined
                                            color="#2E567A"
                                            class="pt-2 os-13-r"
                                            background-color="white"
                                            placeholder="Industry Name"
                                            :rules="shortNameRules"
                                            counter="50"
                                            v-model="industryToUpsert.name"
                                        />
                                    </v-col>

                                    <!-- Company Profile -->
                                    <v-col
                                        cols="12"
                                        class="py-0 os-15-sb"
                                    >
                                        <v-checkbox
                                            v-model="industryToUpsert.is_profile_company"
                                            :ripple="false"
                                            label="Company"
                                            dense
                                            hide-details
                                        />
                                    </v-col>
                                    <!-- Company Profile -->

                                    <!-- Individual Profile -->
                                    <v-col
                                        cols="12"
                                        class="py-0 os-15-sb"
                                    >
                                        <v-checkbox
                                            v-model="industryToUpsert.is_profile_individual"
                                            :ripple="false"
                                            label="Individual"
                                            dense
                                            hide-details
                                        />
                                    </v-col>
                                    <!-- Individual Profile -->

                                    <!-- Job -->
                                    <v-col
                                        cols="12"
                                        class="py-0 os-15-sb"
                                    >
                                        <v-checkbox
                                            v-model="industryToUpsert.is_job"
                                            :ripple="false"
                                            label="Job"
                                            dense
                                            hide-details
                                        />
                                    </v-col>
                                    <!-- Job -->

                                    <!-- Professional Service -->
                                    <v-col
                                        cols="12"
                                        class="py-0 os-15-sb"
                                    >
                                        <v-checkbox
                                            v-model="industryToUpsert.is_prof_service"
                                            :ripple="false"
                                            label="Professional Service"
                                            dense
                                            hide-details
                                        />
                                    </v-col>
                                    <!-- Professional Service -->
                                </v-row>
                            </v-form>
                        </v-col>
                        <!-- Industry/Job Title Form -->

                        <!-- Add/Edit/Cancel Buttons -->
                        <v-col
                            align-self="end"
                            class="pb-0"
                        >
                            <v-btn
                                class="white--text no-uppercase rounded-lg os-13-sb"
                                color="#FFA858"
                                @click="upsertIndustry(false)"
                                :disabled="!validIndustryForm"
                            >
                                <span v-if="action === 'add'">Add</span><span v-else-if="action === 'edit'">Edit</span> Industry
                                <v-icon
                                    class="ml-1"
                                    v-if="action === 'add'"
                                >
                                    {{ mdiPlusCircleOutline }}
                                </v-icon>
                                <v-icon
                                    class="ml-1"
                                    v-else-if="action === 'edit'"
                                >
                                    {{ mdiCircleEditOutline }}
                                </v-icon>
                            </v-btn>
                            <v-btn
                                text
                                class="no-uppercase rounded-lg os-13-sb"
                                color="#8F9BB3"
                                @click="showAddEditIndustry(false)"
                            >
                                Cancel
                            </v-btn>
                        </v-col>
                        <!-- Add/Edit/Cancel Buttons -->
                    </v-row>
                </v-col>
            </v-row>
            <!-- Desktop -->

            <!-- Mobile -->
            <v-row
                v-if="showAddEditIndustryBoolean && $vuetify.breakpoint.smAndDown"
                style="position: absolute; z-index: 1; top: 0; background-color: #F9FBFD; width: 100%; min-height: 100%;"
                class="mt-n1"
            >
                <v-col
                    cols="12"
                >
                    <v-form
                        v-model="validIndustryForm"
                        ref="industryForm"
                    >
                        <v-row>
                            <!-- Industry Form -->
                            <v-col
                                cols="12"
                                class="os-22-sb"
                            >
                                <span v-if="action === 'add'">Add</span><span v-else-if="action === 'edit'">Edit</span> an Industry
                            </v-col>
                            <v-col
                                cols="12"
                                class="py-0"
                            >
                                <v-divider />
                            </v-col>
                            <v-col
                                cols="12"
                                :sm="isIndividual ? 6 : 12"
                                class="pb-0 os-15-sb"
                            >
                                Industry<span style="color: red;">*</span>
                                <br />
                                <v-text-field
                                    dense
                                    outlined
                                    color="#2E567A"
                                    class="pt-2 os-13-r"
                                    background-color="white"
                                    placeholder="Industry Name"
                                    :rules="shortNameRules"
                                    counter="50"
                                    v-model="industryToUpsert.name"
                                />
                            </v-col>

                            <!-- Company Profile -->
                            <v-col
                                cols="12"
                                class="py-0 os-15-sb"
                            >
                                <v-checkbox
                                    v-model="industryToUpsert.is_profile_company"
                                    :ripple="false"
                                    label="Company"
                                    dense
                                    hide-details
                                />
                            </v-col>
                            <!-- Company Profile -->

                            <!-- Individual Profile -->
                            <v-col
                                cols="12"
                                class="py-0 os-15-sb"
                            >
                                <v-checkbox
                                    v-model="industryToUpsert.is_profile_individual"
                                    :ripple="false"
                                    label="Individual"
                                    dense
                                    hide-details
                                />
                            </v-col>
                            <!-- Individual Profile -->

                            <!-- Job -->
                            <v-col
                                cols="12"
                                class="py-0 os-15-sb"
                            >
                                <v-checkbox
                                    v-model="industryToUpsert.is_job"
                                    :ripple="false"
                                    label="Job"
                                    dense
                                    hide-details
                                />
                            </v-col>
                            <!-- Job -->

                            <!-- Professional Service -->
                            <v-col
                                cols="12"
                                class="py-0 os-15-sb"
                            >
                                <v-checkbox
                                    v-model="industryToUpsert.is_prof_service"
                                    :ripple="false"
                                    label="Professional Service"
                                    dense
                                    hide-details
                                />
                            </v-col>
                            <!-- Professional Service -->
                            <!-- Industry Form -->

                            <!-- Add/Edit/Cancel Buttons -->
                            <v-col
                                cols="12"
                                class="text-right"
                            >
                                <v-btn
                                    class="white--text no-uppercase rounded-lg os-13-sb"
                                    color="#FFA858"
                                    @click="upsertIndustry(false)"
                                    :disabled="!validIndustryForm"
                                >
                                    <span v-if="!$store.state.general.customBreakpoints.xxs"><span v-if="action === 'add'">Add</span><span v-else-if="action === 'edit'">Edit</span></span>
                                    <v-icon
                                        class="ml-1"
                                        v-if="action === 'add'"
                                    >
                                        {{ mdiPlusCircleOutline }}
                                    </v-icon>
                                    <v-icon
                                        class="ml-1"
                                        v-else-if="action === 'edit'"
                                    >
                                        {{ mdiCircleEditOutline }}
                                    </v-icon>
                                </v-btn>
                                <v-btn
                                    text
                                    class="no-uppercase rounded-lg os-13-sb"
                                    color="#8F9BB3"
                                    @click="showAddEditIndustry(false)"
                                >
                                    Cancel
                                </v-btn>
                            </v-col>
                            <!-- Add/Edit/Cancel Buttons -->
                        </v-row>
                    </v-form>
                </v-col>
            </v-row>
            <!-- Mobile -->
            <!-- Add View -->
        </v-col>
    </v-row>
</template>
<script>
    import { LookupIndustryController } from '@/logic/controller/index.controller.js'
    import { mdiPlusCircleOutline, mdiArrowRightThinCircleOutline, mdiTrashCanOutline, mdiCircleEditOutline } from '@mdi/js'

    export default {
        name: 'AdminIndustryPage',

        computed: {
            noResultsText () {
                return 'No results found for: "' + this.search + '"'
            },

            noDataText () {
                return 'No industries added'
            },

            shortNameRules () {
                if (this.industryToUpsert.name) {
                    return [
                        v => !!v || 'Required field',
                        v => v.replace(/\s/g, '') !== '' || 'Required field',
                        v => v.length <= 50 || 'Max 50 characters'
                    ]
                } else return [ 'Required field' ]
            }
        },

        data () {
            return {
                lookupIndustryController: null,
                industries: [],
                industryHeaders: [
                    {
                        text: 'Industry',
                        value: 'name'
                    },
                    { text: 'Company', value: 'is_profile_company' },
                    { text: 'Individual', value: 'is_profile_individual' },
                    { text: 'Job', value: 'is_job' },
                    { text: 'Professional Service', value: 'is_prof_service' },
                    {
                        text: 'Action',
                        value: 'action'
                    }
                ],
                mdiPlusCircleOutline,
                mdiArrowRightThinCircleOutline,
                mdiTrashCanOutline,
                mdiCircleEditOutline,
                search: '',
                showAddEditIndustryBoolean: false,
                validIndustryForm: false,
                action: 'add',
                industryToUpsert: {},
                lastPageLoaded: 0,
                totalPages: 0,
                isLoading: true
            }
        },

        beforeMount () {
            this.lookupIndustryController = new LookupIndustryController()
        },

        async mounted () {
            await this.getIndustries()

            var self = this
        
            const industryContainer = document.getElementById('industryContainer')
            industryContainer.addEventListener('scroll', async () => {
                if (industryContainer.offsetHeight + industryContainer.scrollTop + 120 >= industryContainer.scrollHeight) {
                    if (!self.isLoading && self.lastPageLoaded < self.totalPages) {
                        await this.getIndustries()
                    }
                }
            })
        },

        methods: {
            async getIndustries () {
                try {
                    this.isLoading = true

                    this.lastPageLoaded = Number(this.lastPageLoaded) + 1

                    const recordsToRetrieve = 20

                    const response = await this.lookupIndustryController.get(null, null, null, null, null, null, this.lastPageLoaded, recordsToRetrieve)

                    if (response && response.length > 0) {
                        this.totalPages = response[0].page_total
                        for (let index = 0; index < response.length; index++) {
                            const item = response[index]

                            this.industries.push(item)
                        }
                    }

                    this.isLoading = false

                    this.$nextTick(async () => {
                        const industryContainer = document.getElementById('industryContainer')
                        if (response && response.length > 0 && this.lastPageLoaded < this.totalPages && industryContainer.offsetHeight + industryContainer.scrollTop === industryContainer.scrollHeight) await this.getIndustries()
                    }) 
                } catch (error) {
                    this.isLoading = false

                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async upsertIndustry (isCheckbox, checkboxIndustry) {
                try {
                    if (isCheckbox) this.industryToUpsert = checkboxIndustry

                    const response = await this.lookupIndustryController.upsert(this.industryToUpsert)

                    response.is_job = !!response.is_job
                    response.is_prof_service = !!response.is_prof_service
                    response.is_profile_company = !!response.is_profile_company
                    response.is_profile_individual = !!response.is_profile_individual

                    if (this.action === 'edit' || isCheckbox) {
                        const industryIndex = this.industries.findIndex(industryToFind => industryToFind.id === response.id)
                        if (industryIndex > -1) this.$set(this.industries, industryIndex, response) // this.industries[industryIndex] = response

                        this.$store.commit('setSnackbar', {
                            text: 'Updated successfully',
                            show: true,
                            color: '#069B34'
                        })
                    } else if (this.action === 'add' && this.lastPageLoaded === this.totalPages) {
                        this.industries.push(response)
                    }

                    this.showAddEditIndustryBoolean = false
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            showAddEditIndustry (value) {
                this.showAddEditIndustryBoolean = value
                this.action = 'add'

                this.industryToUpsert = {}
            },

            menuItemAction (action, item) {
                if (action === 'Edit') {
                    Object.assign(this.industryToUpsert, item)
                    this.action = 'edit'
                    this.showAddEditIndustryBoolean = true
                }
            }
        }
    }
</script>
<style>
    @import url('../../../assets/style/style.fonts.css');

    .no-uppercase {
        text-transform: none !important;
        letter-spacing: 0px !important;
    }
</style>
